import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 获取出库单信息
export function receive(data){
    return request('/workshop/receive.do',"POST",data)
}
// 获取出库单信息
export function getDeliveryInfo(data){
    return request('/workshop/getDeliveryInfo.do',"POST",data)
}
// 获取出库单信息
export function getDeliveryInfoByType(data){
    return request('/workshop/getDeliveryInfoByType.do',"POST",data)
}
// 更新接收人
export function updateRecipient(data){
    return request('/workshop/updateRecipient.do',"POST",Object.assign({recipientId:SystemUtils.loginUser.id,recipient:SystemUtils.loginUser.name},data))
}

// 车间接收保存
export function recipientSave(data){
    return request('/workshop/recipientSave.do',"POST",data)
}

