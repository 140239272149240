<template >
  <ion-page>
    <Header type="0" title="车间零件接收"> </Header>

    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>出库单</div>
          <div class="right">
            <div class="searchBox">
              <input
                @input="searchHandler"
                v-model="stageCode"
                placeholder="请扫描出库单二维码"
              />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>零件编号</div>
          <div class="right">
            <div class="searchBox">
              <input
                @input="scanPart"
                v-model="partCode"
                placeholder="请扫描零件二维码"
              />
            </div>
          </div>
        </div>
        <!-- <div class="item">
          <div class="left"><span class="required">*</span>出库单</div>
          <div class="right">
            <ion-select
              placeholder="请选择出库单"
              @ionChange="ionChange(billId, part)"
              v-model="billId"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
            >
              <ion-select-option
                v-for="(item, index) in ckdOptions"
                :key="index"
                :value="item.id"
                >{{ item.code }}</ion-select-option
              >
            </ion-select>
          </div>
        </div> -->
        <div class="item">
          <div class="left">接收人</div>
          <div class="right">
            <ion-input disabled v-model="name"></ion-input>
          </div>
        </div>
      </div>

      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div
        v-for="(item, idx) in tableList"
        :key="item.id"
        class="app-container"
      >
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">数量</div>
          <div class="item-content">{{ item.outNum }}</div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 25px"
        >
          <div class="item-label">已接收数量</div>

          <!-- 非检查件 -->
          <div class="item-content" v-if="item.ISCHECK == 0">
            <ion-input
              v-model.number="item.recipientNum"
              @ionChange="changeNum(item, idx)"
              placeholder="请输入接收数量"
              style="text-align: right; --padding-end: 0"
            ></ion-input>
          </div>
          <!-- 检验件 -->
          <div class="item-content" v-else>{{ item.recipientNum || 0 }}</div>
        </div>
      </div>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
    <ion-footer>
      <ion-button expand="block" @click="saveWords">保存</ion-button>
      <ion-button expand="block" @click="commit">提交</ion-button>
    </ion-footer>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  loadingController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import {
  receive,
  getDeliveryInfo,
  updateRecipient,
  recipientSave,
} from "@/api/storeManagement/workshopPartReception";
import { getDeliveryInfoByType } from "@/api/storeManagement/partsofWarehouse";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import scanner from "@/components/cordova/qrScanner.vue";
import SystemUtils from "@/utils/SystemUtils";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    uploadImg,
    CardLoading,
    ionAlert,
    scanner,
  },
  data() {
    return {
      qrCode,
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      idx: 0,
      billId: "",
      part: {},
      ckdOptions: [],
      showScan: false,
      name: "",
      stageCode: "", //出库单
      partCode: "",
    };
  },
  mounted() {
    this.name = SystemUtils.loginUser.name;
  },
  methods: {
    getList(content) {
      this.part = content;
      this.islist = true;
      getDeliveryInfoByType({
        type: 2,
        partCode: content.partCode,
        stageCode: content.stageCode,
        partVersion: content.partVersion,
      }).then((res) => {
        this.islist = false;
        if (!res.data.isError && res.data.data.isSuccess) {
          if (res.data.data.resultData) {
            this.billId =
              res.data.data.resultData.length > 0
                ? res.data.data.resultData[0].id
                : "";
            this.ckdOptions = res.data.data.resultData;
          } else {
            this.billId = res.data.data.bill.id;
            this.ckdOptions.push(res.data.data.bill);
            this.tableList = res.data.data.item;
            for (var i = 0; i < this.tableList.length; i++) {
              this.tableList[i].recipientNum = 0;
              if (this.tableList[i].partCode == content.partCode) {
                this.tableList[i].recipientNum = 1;
              }
            }
          }
        } else {
          Utils.presentToastTopWarning(
            es.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },

    transfer(type) {
      console.log("img");
      this.showScan = true;
    },
    async commit() {
      const _this = this;
      if (_this.tableList.length == 0) {
        Utils.presentToastTopWarning("无可接收零件", "danger");
        return false;
      }
      for (var i = 0; i < _this.tableList.length; i++) {
        if (_this.tableList[i].recipientNum < _this.tableList[i].outNum) {
          Utils.presentToastTopWarning(
            `零件"${_this.tableList[i].partNameCN}"接收数量与出库数量不相等`,
            "danger"
          );
          return false;
        }
      }
      const user = SystemUtils.loginUser;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();

              updateRecipient({
                id: _this.billId,
                datas: _this.tableList,
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("提交成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async saveWords() {
      const _this = this;
      if (_this.tableList.length == 0) {
        Utils.presentToastTopWarning("无可接收零件", "danger");
        return false;
      }

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });
      await loading.present();

      recipientSave({ id: _this.billId, datas: _this.tableList }).then(
        (res) => {
          loading.dismiss();
          if (!res.data.isError && res.data.data.isSuccess) {
            Utils.presentToastTopWarning("保存成功", "success");
          } else {
            Utils.presentToastTopWarning(
              res.data.message || res.data.data.message,
              "danger"
            );
          }
        }
      );
    },
    ionChange(id) {
      this.islist = true;
      this.tableList = [];
      getDeliveryInfoByType({ id: id,type:2 }).then((res) => {
        this.islist = false;
        if (!res.data.isError && res.data.data.isSuccess) {
          this.tableList = res.data.data.item || [];
          this.tableList.forEach((e) => {
            e.recipientNum = e.recipientNum ? e.recipientNum : 0;
          });
          this.stageCode = res.data.data.bill.code;
          this.billId = res.data.data.bill.id;
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    searchHandler(searchParam) {
      if (Utils.isInCludeEmoji(searchParam.target.value)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.stageCode.trim();

        if (scanVal.indexOf('"') > -1) {
          _this.stageCode = "";
          if (scanVal.indexOf("id") > -1) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            _this.ionChange(contents.id);
          } else {
            Utils.presentToastTopWarning("请扫描出库单二维码", "danger");
            _this.stageCode = "";
          }
        }
      });
    },
    scanPart() {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.partCode.trim();

        if (scanVal.indexOf('"') > -1 && scanVal.indexOf("partCode") > -1) {
          _this.partCode = "";

          var contents = JSON.parse(
            "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
          );
          _this.partCode = contents.partCode;
          if (_this.tableList.length > 0) {
            if (
              !_this.tableList.some((e) => {
                return e.partCode == contents.partCode;
              })
            ) {
              Utils.presentToastTopWarning(
                "当前出库单下未找到此零件",
                "danger"
              );
              return false;
            }
            for (var i = 0; i < _this.tableList.length; i++) {
              if (_this.tableList[i].partCode == contents.partCode) {
                _this.tableList[i].recipientNum = ++_this.tableList[i]
                  .recipientNum;
                if (
                  _this.tableList[i].recipientNum > _this.tableList[i].outNum
                ) {
                  _this.tableList[i].recipientNum = _this.tableList[i].outNum;
                  Utils.presentToastTopWarning(
                    _this.tableList[i].partNameCN + "接收数量不能大于出库数量",
                    "danger"
                  );
                }
              }
            }
          }
        } else {
          Utils.presentToastTopWarning("请扫描零件二维码", "danger");
          _this.partCode = "";
        }
      });
    },
    closeScan(contents) {
      this.showScan = false;
      if (contents.partCode && contents.partVersion && contents.stageCode) {
        if (this.tableList.length > 0) {
          if (
            !this.tableList.some((e) => {
              return e.partCode == contents.partCode;
            })
          ) {
            Utils.presentToastTopWarning("当前出库单下未找到此零件", "danger");
            return false;
          }
          for (var i = 0; i < this.tableList.length; i++) {
            if (this.tableList[i].partCode == contents.partCode) {
              this.tableList[i].recipientNum = this.tableList[i].recipientNum
                ? ++this.tableList[i].recipientNum
                : 1;
              if (this.tableList[i].recipientNum > this.tableList[i].outNum) {
                this.tableList[i].recipientNum = this.tableList[i].outNum;
                Utils.presentToastTopWarning(
                  this.tableList[i].partNameCN + "接收数量不能大于出库数量",
                  "danger"
                );
              }
            }
          }
        } else {
          this.getList(contents);
        }
      } else {
        Utils.presentToastTopWarning("请扫描零件二维码", "danger");
      }
    },
    // 更改接收数量
    changeNum(item, idx) {
      console.log(this.tableList[idx].outNum);
      this.$nextTick(()=>{
        if (this.tableList[idx].recipientNum > this.tableList[idx].outNum && this.tableList[idx].recipientNum !== 0) {
        this.tableList[idx].recipientNum = this.tableList[idx].outNum;
        Utils.presentToastTopWarning("接收数量不能大于数量", "danger");
      }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
input {
  border: 0;
  outline: none;
  text-align: right;
}
</style>